import Infant from "../assets/photo-gallery/infant2.jpeg";
import Infant2 from "../assets/photo-gallery/infant3.jpeg";
import Infant3 from "../assets/photo-gallery/infants4.jpeg";

import PreSchool from "../assets/photo-gallery/preschool4.jpeg";
import PreSchool2 from "../assets/photo-gallery/preschool6.jpeg";
import PreSchool3 from "../assets/photo-gallery/preschool7.jpeg";
import PreSchool4 from "../assets/photo-gallery/preschool9.jpeg";
import PreSchool5 from "../assets/photo-gallery/preschool10.jpeg";
import PreSchool6 from "../assets/photo-gallery/preschool11.jpeg";

import SchoolAge from "../assets/photo-gallery/redroom.jpeg";
import SchoolAge2 from "../assets/photo-gallery/schoolage.jpeg";
import SchoolAge3 from "../assets/photo-gallery/schoolage2.jpeg";
import SchoolAge4 from "../assets/photo-gallery/schoolage4.jpeg";
import SchoolAge5 from "../assets/photo-gallery/schoolage5.jpeg";
import SchoolAge6 from "../assets/photo-gallery/schoolage6.jpeg";
import SchoolAge7 from "../assets/photo-gallery/schoolage7.jpeg";
import SchoolAge8 from "../assets/photo-gallery/schoolage8.jpeg";

import Toddler from "../assets/photo-gallery/toddler8.jpeg";
import Toddler2 from "../assets/photo-gallery/toddler9.jpeg";
import Toddler3 from "../assets/photo-gallery/toddler10.jpeg";
import Toddler4 from "../assets/photo-gallery/toddler11.jpeg";
import Toddler5 from "../assets/photo-gallery/toodler2.jpeg";


export const photos = [{
    original: Infant,
    thumbnail: Infant,
},
{
    original: Infant2,
    thumbnail: Infant2,
},
{
    original: Infant3,
    thumbnail: Infant3,
},
{
    original: PreSchool,
    thumbnail: PreSchool,
},
{
    original: PreSchool2,
    thumbnail: PreSchool2,
},

{
    original: PreSchool3,
    thumbnail: PreSchool3,

},
{
    original: PreSchool4,
    thumbnail: PreSchool4,

},
{
    original: PreSchool5,
    thumbnail: PreSchool5,

},
{
    original: PreSchool6,
    thumbnail: PreSchool6,

},
{
    original: SchoolAge,
    thumbnail: SchoolAge,

},
{
    original: SchoolAge2,
    thumbnail: SchoolAge2,

},
{
    original: SchoolAge3,
    thumbnail: SchoolAge3,
},
{
    original: SchoolAge4,
    thumbnail: SchoolAge4,

},
{
    original: SchoolAge5,
    thumbnail: SchoolAge5,
},
{
    original: SchoolAge6,
    thumbnail: SchoolAge6,

},
{
    original: SchoolAge7,
    thumbnail: SchoolAge7,
},
{
    original: SchoolAge8,
    thumbnail: SchoolAge8,

},
{
    original: Toddler,
    thumbnail: Toddler,

},
{
    original: Toddler2,
    thumbnail: Toddler2,

},
{
    original: Toddler3,
    thumbnail: Toddler3,

},
{
    original: Toddler4,
    thumbnail: Toddler4,

},
{
    original: Toddler5,
    thumbnail: Toddler5,
},

];