import React from "react";
import { NavLink } from "react-router-dom";

export default function Cost() {
    return (
        <div>
            <div id="pageContainer">
                <span className="sectionHeader"> Tuition and Fees </span>
                <table>
                    <caption class="tableCaption">Nursery 6 weeks to 18 months</caption>
                    <thead>
                        <tr >
                            <th></th>
                            <th>1-3 Days</th>
                            <th>4-5 Days</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1st Child
                            </td>
                            <td>$195</td>
                            <td>$295</td>
                        </tr>
                    </tbody>

                </table>
                <table>
                    <caption class="tableCaption">Toddler 18 months to 3 years</caption>
                    <thead>
                        <tr >
                            <th></th>
                            <th>3days / Part Time</th>
                            <th>4-5 Days</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1st Child
                            </td>
                            <td>$175</td>
                            <td>$265</td>
                        </tr>
                        <tr>
                            <td>
                                2nd Child
                            </td>
                            <td>$157</td>
                            <td>$212</td>
                        </tr>
                    </tbody>

                </table>
                <table>
                    <caption class="tableCaption">PresSchool / PreK</caption>
                    <thead>
                        <tr >
                            <th></th>
                            <th>3 Days/ Part time</th>
                            <th>4-5 Days</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1st Child
                            </td>
                            <td>$150</td>
                            <td>$232</td>
                        </tr>
                        <tr>
                            <td>
                                2nd Child
                            </td>
                            <td>$135</td>
                            <td>$185.6</td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <caption class="tableCaption">School Age Summer</caption>
                    <thead>
                        <tr >
                            <th></th>
                            <th>1-3 Days/Part Time</th>
                            <th>4-5 Days</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1st Child
                            </td>
                            <td>$150</td>
                            <td>$209</td>
                        </tr>
                        <tr>
                            <td>
                                2nd Child
                            </td>
                            <td>135</td>
                            <td>168</td>

                        </tr>
                    </tbody>

                </table>
                <table>
                    <caption class="tableCaption">School Age</caption>
                    <thead>
                        <tr >
                            <th></th>
                            <th>Before & After<br />4-5 Days</th>
                            <th>Before & After<br />1-3 Days</th>
                            <th>Before<br />1-5 Days</th>
                            <th>After<br />1-5 Days</th>
                            <th>Snow Days<br />and any days<br />off of School<br />(daily rate)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1st Child
                            </td>
                            <td class="schoolAgeCol">$102</td>
                            <td class="schoolAgeCol">$81</td>
                            <td class="schoolAgeCol">$55</td>
                            <td class="schoolAgeCol">$70</td>
                            <td class="schoolAgeCol">$65</td>
                        </tr>
                        <tr>
                            <td>
                                2nd Child
                            </td>
                            <td class="schoolAgeCol">$76</td>
                            <td class="schoolAgeCol">$63</td>
                            <td class="schoolAgeCol">$40.5</td>
                            <td class="schoolAgeCol">$54</td>
                            <td class="schoolAgeCol">$60</td>
                        </tr>
                        <tr>
                            <td class="tableExp" colSpan="5">Daily rate for snow days and any scheduled days off of school is $35, if child is enrolled for Before and /or After Care.</td>

                        </tr>

                    </tbody>

                </table>
                <br />
                <h2 id="rateH2">RATE WILL VERY BASED ON TRANSPORTATION NEEDS</h2>
                <p id="rateExplanation">
                    <span id="parHeader"> Registration Fee: </span>
                    $75.00 per family *Due upon registration & any lengthy
                    withdraw and re-enroll *non-refundable <br />
                    <span id="parHeader"> Preschool: </span> 3 years old and
                    Potty trained.
                    <br />
                    <span id="parHeader"> Full-Time: </span> 4 or 5 days of
                    child care Monday-Friday consisting of 5 or more hours per
                    day
                    <br />
                    <span id="parHeader"> Part-Time: </span> Children who
                    receive 5 or less hours of child care per day or are
                    enrolled 2-3 days a week
                    <br />
                    <span id="parHeader"> Daily Rate: </span>minimum of 2 full
                    days
                    <br />
                    <span id="parHeader"> Snow Days:</span> As a courtesy to our
                    families, you may drop off your school aged child (up to the
                    age of 12) and be charged the daily rate. This service has
                    limited enrollment. PLEASE ALWAYS CALL AHEAD FOR
                    CONFIRMATION. For Families already enrolled in our Before &
                    After care Program the fee is $24.00 a day.
                    <br />
                    <span id="parHeader"> Late Pick Up Fee: </span>$15 per child
                    every 15 minutes here past 6:00 pm Monday--Thursday & 5:30pm
                    Friday.
                    <br />
                    <span id="parHeader">2 ½ Hour Preschool/PreK </span>
                    Monday thru Friday 9:00am to 12:00. Toddler $103 Preschool/PreK $88
                </p>
                <br />
                <h2>
                    ***Hours of Operation: Monday thru Thursday 5:30AM— 6PM
                    Friday 5:30AM — 5:30PM <br />***effective 02/07/22
                </h2>
                <br />
            </div>
            <div>
                <p className="sectionHeaderPrograms">
                    <NavLink to="/tuition">
                        {" "}
                        How to Pay Tuition
                        <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"></i>
                        <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"></i>
                    </NavLink>
                </p>
            </div>
        </div >
    );
}
