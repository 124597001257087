import React, { useState, useCallback } from "react";
import ImageGallery from "react-image-gallery";
import { photos } from "../components/ImageCart";
import { NavLink } from "react-router-dom";

const images = photos;

const PhotoGallery = () => {
    return (
        <div id="galleryContainer">
            <div id="galleryTop">
                <p className="sectionHeader">Gallery</p>
                <ImageGallery items={images}
                    showFullscreenButton={true}
                />
            </div>
            <div>
                <p className="sectionHeaderPrograms">
                    <NavLink to="/career">
                        {" "}

                        {" "}
                        Career{" "}
                        <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"></i>
                        <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"></i>
                    </NavLink>
                </p>
            </div>
        </div>
    );
};
export default PhotoGallery;
