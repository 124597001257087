import React from "react";

export default function Construction() {
    return (
        <div className="construction">
            <h1>
                Under Construction! <br />
                Thank you for your patience!
            </h1>
        </div>
    );
}
